<template>
  <div class="card p-4 border-radius-xl bg-white mb-4 w-100" style="min-height: 230px;">
    <div class="col d-flex flex-column justify-content-between">
      <h6>Amount</h6>
      <input id="amount" type="number" placeholder="$3000" value="3000"
        class="form-control bg-gray-100 border-0 py-3 h6" />
      <p class="text-sm mt-2 mb-0">
        Please enter the amount of investment from internal wallet to wtihdraw to your wallet address.
      </p>
    </div>
  </div>
</template>

<style scoped>
/* Remove arrow in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}
</style>
