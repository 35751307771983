<template>
  <div class="container-fluid py-7">
    <div class="col-12 col-lg-8 mx-auto mb-4">
      <div class="card p-4 border-radius-xl bg-white">
        <h5 class="font-weight-bolder mb-0">KYC Verification</h5>
        <p class="sub-heading">
          Identification Card / Driving License / Passport
        </p>
        <div class="row mb-2">
          <div class="col-12 col-sm-6">
            <p class="sub-heading m-1">Sample - Identification Card</p>
            <div
              class="form-control d-flex justify-content-center p-3"
              style="height: 209px"
            >
              <img
                src="@/assets/img/KYCIC.png"
                alt="Document Sample"
                style="max-width: 100%"
              />
            </div>
            <p class="small text-center m-3">
              *This is the first and most straightforward KYC process.
            </p>
          </div>
          <div class="col-12 col-sm-6">
            <p class="sub-heading m-1">Sample - Photo</p>
            <div
              class="form-control d-flex justify-content-center p-3"
              style="height: 209px"
            >
              <img
                src="@/assets/img/KYCSelfie.png"
                alt="Selfie Sample"
                style="max-width: 100%"
              />
            </div>
            <p class="small text-center m-3">
              *This is the first and most straightforward KYC process.
            </p>
          </div>
        </div>
        <!-- Upload documents -->
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="sub-heading m-1">Upload your identification document</p>
            <form
              id="dropzone1"
              action="/file-upload"
              class="form-control dropzone mb-4"
            >
              <div class="dz-message text-muted" data-dz-message>
                <span
                  >Drop files here to upload <br />(PNG, JPEG, JPG, PDF)
                </span>
              </div>
              <div class="fallback">
                <input name="file" type="file" />
              </div>
            </form>
          </div>
          <div class="col-12 col-sm-6">
            <p class="sub-heading m-1">Upload a photo of yourself</p>
            <form
              id="dropzone2"
              action="/file-upload"
              class="form-control dropzone mb-4"
            >
              <div class="dz-message text-muted" data-dz-message>
                <span
                  >Drop files here to upload <br />(PNG, JPEG, JPG, PDF)
                </span>
              </div>
              <div class="fallback">
                <input name="file" type="file" />
              </div>
            </form>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="w-90">
            <p class="small text-center">
              *Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <argon-button type="button" color="success" class="border w-30"
            >Submit
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import Dropzone from "dropzone";
import { onMounted } from "vue";

/* Dropzone */
onMounted(() => {
  Dropzone.autoDiscover = false;
  new Dropzone("#dropzone1", {
    url: "/file/post",
    addRemoveLinks: true,
    maxFiles: 1,
    init: function () {
      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
    acceptedFiles: ".png, .jpeg, .jpg, .pdf",
  });
  new Dropzone("#dropzone2", {
    url: "/file/post",
    addRemoveLinks: true,
    maxFiles: 1,
    init: function () {
      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
    acceptedFiles: ".png, .jpeg, .jpg, .pdf",
  });
});
</script>

<style scoped>
.sub-heading {
  margin-top: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #172b4d;
}

.dropzone {
  display: flex;
  justify-content: center;
}
</style>
