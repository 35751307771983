<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Admin List</h5>
          </div>
          <div class="d-flex mx-4 justify-content-end">
            <argon-button
              type="button"
              color="success"
              @click="onAddNewAdminButtonClicked"
              >Add New Admin
            </argon-button>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="admin.id" v-for="admin in admins">
                  <td>{{ admin.id }}</td>
                  <td>{{ admin.email }}</td>
                  <td>{{ admin.role }}</td>
                  <td class="text-center">
                    <router-link
                      :to="'/admin/admin/changepassword/'"
                      class="icon icon-shape"
                      ><i class="fas fa-lock"></i
                    ></router-link>
                    <router-link
                      :to="'/admin/admin/edit/'"
                      class="icon icon-shape mx-3"
                      ><i class="fas fa-edit"></i
                    ></router-link>
                    <a class="icon icon-shape sweetalerts">
                      <i class="fas fa-trash"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import swal from "sweetalert2";

// Routes //
const router = useRouter();

const onAddNewAdminButtonClicked = () => {
  router.push("/admin/admin/add");
};

// Test Data //
const admins = reactive([
  {
    id: 12,
    email: "abc@gmail.com",
    role: "admin",
  },
  {
    id: 121,
    email: "abc@gmail.com",
    role: "admin",
  },
  {
    id: 2222,
    email: "abc@gmail.com",
    role: "admin",
  },
  {
    id: 33333,
    email: "abc@gmail.com",
    role: "admin",
  },
  {
    id: 4444,
    email: "abc@gmail.com",
    role: "admin",
  },
  {
    id: 555,
    email: "abcz@gmail.com",
    role: "admin",
  },
]);

// Admin List Table //
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const datatable = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
  });

  datatable.on("datatable.init", function () {
    var sweetalerts = document.querySelectorAll(".sweetalerts");
    sweetalerts.forEach(function (sweetalert) {
      sweetalert.addEventListener("click", function () {
        showSwal();
      });
    });
  });
});

// Sweet Alert //
const showSwal = () => {
  {
    new swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn bg-gradient-success",
        cancelButton: "btn bg-gradient-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        swal({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swal.dismiss;
      }
    });
  }
};
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}

.icon {
  background-color: #f8f9fa;
  border-radius: 50%;
}
.icon > i {
  color: #8392ab;
  font-size: 0.875rem;
  line-height: 1.5;
  opacity: 1;
}
.icon:hover > i {
  color: #5e72e4;
}
</style>
