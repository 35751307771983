<template>
  <main class="main-content main-content-bg">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-body text-center">
                <img src="@/assets/img/icons/loading.png" alt="loading" />
                <h4 class="mt-3">
                  Please wait while we're redirecting you to the payment page
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
