<template>
  <div class="container-fluid py-4">
    <div class="card mt-4">
      <div class="card-header">
        <h5>Inject Trades</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="form-label">Symbol</label>
            <select
              id="choices-symbols"
              class="form-symbols"
              name="choices-symbols"
            >
              <option value="EURUSD">EURUSD</option>
              <option value="BTC">BTC</option>
              <option value="ETH">ETH</option>
            </select>
          </div>
          <div class="col-sm-3 col-6">
            <label class="form-label">Type</label>
            <select id="choices-types" class="form-types" name="choices-types">
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>
          </div>
          <div class="col-sm-3 col-6">
            <label class="form-label">Time</label>
            <argon-input id="time" type="time" placeholder="10:00" />
          </div>
          <div class="col-sm-3 col-6">
            <label class="form-label">Open Price</label>
            <argon-input id="openPrice" type="number" placeholder="10000" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-12 mb-3">
            <label class="form-label">Trader</label>
            <select
              id="choices-traders"
              class="form-traders"
              name="choices-traders"
            >
              <option value="trader a">Trader A</option>
              <option value="trader b">Trader B</option>
              <option value="trader c">Trader C</option>
            </select>
          </div>
          <div class="col-sm-3 col-6">
            <label class="form-label">Number of Trades</label>
            <argon-input id="numberOfTrades" type="number" placeholder="100" />
          </div>
          <div class="col-sm-3 col-6">
            <label class="form-label">Close Price</label>
            <argon-input id="closePrice" type="number" placeholder="10000" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">
            <label class="form-label">Profit (%)</label>
            <argon-input id="profit" type="number" placeholder="100" />
          </div>
          <div class="col-md-6 col-6 align-self-center">
            <div class="button-row d-flex mt-3">
              <argon-button type="button" color="success" class="ms-auto"
                >Submit
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import * as Choices from "choices.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

onMounted(() => {
  // Risk Level //
  if (document.getElementById("choices-types")) {
    var type = document.getElementById("choices-types");
    new Choices(type, {
      searchEnabled: false,
      allowHTML: true,
    });
  }

  // Trading Symbol //
  if (document.getElementById("choices-symbols")) {
    var symbol = document.getElementById("choices-symbols");
    new Choices(symbol, {
      searchEnabled: false,
      allowHTML: true,
    });
  }

  // Traders //
  if (document.getElementById("choices-traders")) {
    var trader = document.getElementById("choices-traders");
    new Choices(trader, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
});
</script>
