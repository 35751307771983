<template>
  <main class="main-content main-content-bg">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-body text-center">
                <img
                  src="@/assets/img/icons/checkmarkCircle2.png"
                  alt="checkmark"
                  width="58px"
                />
                <h4 class="mt-3" style="color: #2dce89">Successful Payment</h4>
                <hr class="w-90 mx-auto" />
                <p class="text-center px-3">
                  Your deposit of $100 is success, redirecting you back to the
                  wallet page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
