<template>
  <div class="container-fluid py-7">
    <div class="col-12 col-lg-8 mx-auto mb-4">
      <div id="delete" class="card mt-4">
        <div class="card-header">
          <h5>Stop Investing</h5>
          <p class="text-sm mb-0">
            Once you delete your account, there is no going back. Please be
            certain.
          </p>
        </div>
        <div class="card-body d-sm-flex pt-0">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div>
              <argon-switch id="delete-account" name="Delete Account" />
            </div>
            <div class="ms-2">
              <span class="text-dark font-weight-bold d-block text-sm"
                >Confirm
              </span>
              <span class="text-xs d-block">I want to stop investing.</span>
            </div>
          </div>
          <argon-button
            color="secondary"
            variant="outline"
            class="mb-0 ms-auto"
            type="button"
            name="button"
            >Deactivate
          </argon-button>
          <argon-button
            color="danger"
            variant="gradient"
            class="mb-0 ms-2"
            type="button"
            name="button"
            >Close Investment
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";

export default {
  name: "SideNavItem",
  components: {
    ArgonButton,
    ArgonSwitch,
  },
};
</script>
