<template>
  <div class="container-fluid py-7">
    <div class="col-12 col-lg-8 mx-auto mb-4">
      <div class="card p-4 border-radius-xl">
        <h5 class="font-weight-bolder">Add New User</h5>
        <div class="row mt-3">
          <div class="col">
            <label>Email</label>
            <argon-input id="email" type="email" placeholder="Email address" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Password</label>
            <argon-input id="password" type="password" placeholder="Password" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Confirm Password</label>
            <argon-input
              id="confirmpassword"
              type="password"
              placeholder="Confirm Password"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Name</label>
            <argon-input id="name" type="text" placeholder="Name" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label>Date of Birth</label>
            <flat-pickr
              v-model="data.dateOfBirth"
              class="form-control datetimepicker"
              placeholder="yyyy-mm-dd"
              :config="data.config"
            ></flat-pickr>
          </div>
          <div class="col-12 col-sm-4 mt-3 mt-sm-0">
            <label>Country</label>
            <select
              id="choices-country"
              class="form-control"
              name="choices-country"
            >
              <option value="Argentina">Argentina</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Brasil">Brasil</option>
            </select>
          </div>
          <div class="col-12 col-sm-4 mt-3 mt-sm-0">
            <label>Referral Code</label>
            <argon-input
              id="referralCode"
              type="text"
              placeholder="Referral Code"
            />
          </div>
        </div>
        <div class="d-flex mt-2 justify-content-end">
          <argon-button type="button" color="success" class="border w-30"
            >Submit
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { reactive, onMounted } from "vue";
import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";

// Country Dropdown //
onMounted(() => {
  if (document.getElementById("choices-country")) {
    var country = document.getElementById("choices-country");
    new Choices(country, { allowHTML: true });
  }
});

// Test Data //
const data = reactive({
  dateOfBirth: "",
  config: {
    allowInput: true,
  },
});
</script>
