<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 mb-8 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>KYC Requests</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>
                    <div class="d-flex align-items-center">
                      <ArgonCheckbox
                        style="font-size: 0.9rem"
                        @click="toggleSelectAll"
                        :checked="selectAll.checked"
                      />
                      Select All
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Total</th>
                  <th>Invested</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="kyc.id"
                  v-for="kyc in kycs"
                  v-show="kyc.action === 'pending'"
                >
                  <td>
                    <ArgonCheckbox
                      class="checkboxes"
                      :checked="kyc.checked"
                      :id="kyc.id"
                    />
                  </td>
                  <td>{{ kyc.name }}</td>
                  <td>${{ kyc.total }}</td>
                  <td>${{ kyc.invested }}</td>
                  <td class="text-center d-flex justify-content-center">
                    <router-link
                      :to="'/admin/kyc/view/'"
                      class="icon icon-shape me-3"
                      ><i class="fas fa-eye"></i
                    ></router-link>
                    <argon-button
                      type="button"
                      color="danger"
                      class="reject me-2"
                      >Reject
                    </argon-button>
                    <argon-button
                      type="button"
                      class="approve ms-2"
                      style="background-color: #2dce89"
                      >Approve
                    </argon-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end card-footer pt-0">
            <argon-button
              type="button"
              color="success"
              @click="onApproveButtonClicked"
              >Approve Selected
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";

// Test Data //
const kycs = reactive([
  {
    id: 12,
    name: "Lorem",
    total: 100,
    invested: 100,
    action: "pending",
    checked: false,
  },
  {
    id: 123,
    name: "Lorem",
    total: 100,
    invested: 100,
    action: "pending",
    checked: false,
  },
  {
    id: 124,
    name: "Lorem",
    total: 100,
    invested: 100,
    action: "pending",
    checked: false,
  },
]);

// Individual Checkbox //
// Issue: need to get array ID //
const toggleChecked = () => {
  kycs[0].checked = !kycs[0].checked;
};

// Select All Checkbox //
// Issue: selectAll updates the data, but the UI does not reflect it. //
const selectAll = reactive({
  checked: false,
});

const toggleSelectAll = () => {
  selectAll.checked = !selectAll.checked;
  for (var i = 0; i < kycs.length; i++) {
    kycs[i].checked = selectAll.checked;
  }
};

// Reject Button //
// Issue: need to get array ID , UI does not reflect update //
const onRejectButtonClicked = () => {
  kycs[0].action = "reject";
  console.log(kycs[0].action);
};

// Approve Button //
// Issue: need to get array ID , UI does not reflect update //
const onApproveButtonClicked = () => {
  kycs[0].action = "approve";
  console.log(kycs[0].action);
};

// Admin List Table //
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const datatable = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
    sortable: false,
  });

  // Individual Checkbox //
  datatable.on("datatable.init", function () {
    var checkboxes = document.querySelectorAll(".checkboxes");
    checkboxes.forEach(function (checkbox) {
      checkbox.addEventListener("click", function () {
        toggleChecked();
      });
    });
  });

  datatable.on("datatable.init", function () {
    var reject = document.querySelectorAll(".reject");
    reject.forEach(function (reject) {
      reject.addEventListener("click", function () {
        onRejectButtonClicked();
      });
    });
  });

  datatable.on("datatable.init", function () {
    var approve = document.querySelectorAll(".approve");
    approve.forEach(function (approve) {
      approve.addEventListener("click", function () {
        onApproveButtonClicked();
      });
    });
  });
});
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}

.icon {
  background-color: #f8f9fa;
  border-radius: 50%;
}
.icon > i {
  color: #8392ab;
  font-size: 0.875rem;
  line-height: 1.5;
  opacity: 1;
}
.icon:hover > i {
  color: #5e72e4;
}
</style>
