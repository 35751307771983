<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Sign In Now</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                    />
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Remember me
                  </argon-switch>
                  <div class="text-center">
                    <argon-button
                      color="success"
                      full-width
                      class="my-4 mb-2"
                      @click="onSignInButtonClicked"
                      >Sign in
                    </argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const onSignInButtonClicked = () => {
  router.push("/admin");
};
</script>
