<template>
  <!-- Withdrawal -->
  <div class="container-fluid mt-6 ms-0">
    <div class="row">
      <div class="col">
        <WalletCard :isDeposit="false" />
      </div>
      <div class="col">
        <AmountCard />
      </div>
      <div class="col">
        <AddressCard />
      </div>
      <div class="row">
        <div class="d-flex justify-content-end">
          <argon-button type="submit" color="success">Submit</argon-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Past Withdrawal Record -->
  <div class="pt-4 pb-6 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Past Withdrawal Record</h5>
            <p class="mb-0 text-sm">
              Past withdrawal records.
            </p>
          </div>
          <div class="table-responsive mt-0">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Time</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Transaction ID</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                  <td>{{ transaction.id }}</td>
                  <td>{{ transaction.time }}</td>
                  <td>{{ transaction.amount }}</td>
                  <td class="font-weight-bold">
                    <img :src="require(`@/assets/img/icons/${transaction.status}Status.png`)"
                      :alt="`${transaction.status}`" width="28" class="me-1" />{{ transaction.status }}
                  </td>
                  <td>{{ transaction.transactionID }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AddressCard from "./components/AddressCard.vue";
import AmountCard from "./components/AmountCard.vue";
import WalletCard from "./components/WalletCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";

// Test Data
const transactions = reactive([
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "success",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "failed",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "pending",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "cancelled",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "success",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "success",
    transactionID: "bc1qxy2kgdygjrsq",
  },
  {
    id: 121212,
    time: "12/12/2021 12:30:21",
    amount: 1001,
    status: "failed",
    transactionID: "bc1qxy2kgdygjrsq",
  },
]);

// Transaction Record Table
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
  });
});
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}
</style>
