<template>
  <div class="container-fluid py-4">
    <div id="basic-info" class="card mt-4">
      <div class="card-header pb-0">
        <h5>Edit Trader</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="avatar avatar-xxl position-relative">
            <img
              src="@/assets/img/bruce-mars.jpg"
              class="border-radius-md"
              alt="team-2"
            />
            <a
              href="#"
              class="bottom-0 btn btn-sm btn-icon-only bg-gradient-light position-absolute end-0 mb-n2 me-n2"
            >
              <i
                class="top-0 fa fa-pen"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                aria-hidden="true"
                data-bs-original-title="Edit Image"
                aria-label="Edit Image"
              ></i>
              <span class="sr-only">Edit Image</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Name</label>
            <argon-input id="name" type="text" placeholder="eg. Michael" />
          </div>
          <div class="col-6">
            <label class="form-label">Country</label>
            <argon-input id="country" type="text" placeholder="eg. England" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-6">
            <label class="form-label">Monthly (%)</label>
            <argon-input id="monthly" type="number" placeholder="100" />
          </div>
          <div class="col-sm-2 col-5">
            <label class="form-label">Risk Level</label>
            <select
              id="choices-riskLevels"
              class="form-riskLevels"
              name="choices-riskLevels"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div class="col-sm-2 col-6">
            <label class="form-label">Success Fee (%)</label>
            <argon-input id="successFee" type="number" placeholder="100" />
          </div>
          <div class="col-sm-6 col-4">
            <label class="form-label">Minimum Deposit</label>
            <argon-input
              id="minimumDeposit"
              type="number"
              placeholder="10000"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <argon-textarea
              id="bio"
              class="multisteps-form__textarea mt-0"
              placeholder="About"
              >About
            </argon-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label mt-2">Trading Symbol</label>
            <input
              id="choices-symbols"
              class="form-control"
              type="text"
              value="EURUSD, BTC, ETH"
              placeholder="Enter something"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
            />
          </div>
          <div class="col-md-6 align-self-center">
            <label class="form-label mt-2">Ratio</label>
            <div class="row">
              <div class="col-5 pe-0">
                <argon-input id="forex" type="number" placeholder="Forex" />
              </div>
              <div class="col-1 text-center">
                <span class="h4">&ratio;</span>
              </div>
              <div class="col-5 ps-0">
                <argon-input id="Crypto" type="number" placeholder="Crypto" />
              </div>
            </div>
          </div>
          <div class="button-row d-flex mt-4">
            <argon-button type="button" color="success" class="ms-auto"
              >Submit
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import * as Choices from "choices.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";

onMounted(() => {
  // Risk Level //
  if (document.getElementById("choices-riskLevels")) {
    var riskLevel = document.getElementById("choices-riskLevels");
    new Choices(riskLevel, {
      searchEnabled: false,
      allowHTML: true,
    });
  }

  // Trading Symbol //
  if (document.getElementById("choices-symbols")) {
    var symbols = document.getElementById("choices-symbols");
    new Choices(symbols, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
      allowHTML: true,
    });
  }
});
</script>
