<template>
  <div class="container-fluid pt-4 d-flex">
    <div class="col-12 col-lg-8 form-group" style="max-width: 500px">
      <h5 class="font-weight-bolder text-white mb-5">Crytocurrency Payment</h5>
      <AmountCard />
      <AddressCard />
      <div class="d-flex justify-content-end">
        <argon-button
          type="submit"
          color="success"
          class="w-30 mt-4"
          @click="onSubmitButtonClicked"
          >Submit
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import ArgonButton from "@/components/ArgonButton.vue";
import AmountCard from "./components/AmountCard.vue";
import AddressCard from "./components/AddressCard.vue";

// Routes
const router = useRouter();

function onSubmitButtonClicked() {
  router.push("submitted/redirect");
}
</script>
