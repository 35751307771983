<template>
  <div class="py-4 container-fluid px-0">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>List of Close Trades</h5>
            <p class="mb-0 text-sm">
              List of closed trades.
            </p>
          </div>
          <div class="table-responsive mt-0">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Symbol</th>
                  <th>Time</th>
                  <th>Type</th>
                  <th>Open Price</th>
                  <th>Close Price</th>
                  <th>Profit</th>
                  <th>Profit Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="closeTrade.id" v-for="closeTrade in closeTrades">
                  <td>{{ closeTrade.id }}</td>
                  <td>{{ closeTrade.symbol }}</td>
                  <td>{{ closeTrade.time }}</td>
                  <td :class="{ green: closeTrade.type === 'Buy' }">
                    {{ closeTrade.type }}
                  </td>
                  <td>${{ closeTrade.openPrice }}</td>
                  <td>${{ closeTrade.closePrice }}</td>
                  <td :class="{ green: closeTrade.profit > 0 }">
                    {{ closeTrade.profit }}%
                  </td>
                  <td :class="{ green: closeTrade.profitAmount > 0 }">
                    {{ closeTrade.profitAmount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";

// Test Data
const closeTrades = reactive([
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
  {
    id: 121212,
    symbol: "Bitcoin",
    time: "12/12/2021 12:30:21",
    type: "Buy",
    openPrice: 1001,
    closePrice: 1001,
    profit: 5,
    profitAmount: 500,
  },
]);

// Transaction Record Table
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
  });
});
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}

.green {
  color: #2dce89;
}
</style>
