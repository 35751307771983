<template>
  <div class="card p-4 border-radius-xl bg-white mb-4 w-100" style="min-height: 230px; min-width: 320px;">
    <div class="col">
      <h6>Wallet Address</h6>
      <input id="address" type="text" placeholder="Wallet Address" class="form-control bg-gray-100 border-0 py-3 h6" />
      <p class="text-sm mt-2 mb-0">
        Please enter the wallet address you used during deposit.
      </p>
    </div>
  </div>
</template>
