<template>
  <div class="row mb-4">
    <div class="col-12">
      <div class="card bg-gray-100 shadow-none">
        <div class="card-body p-0">
          <div>
            <table
              class="table table-borderless text-center text-sm calculator"
            >
              <tr>
                <th></th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td class="font-weight-bold">2021</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
              </tr>
              <tr>
                <td class="bg-gray-100"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">2022</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
                <td>5%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
table td {
  background-color: #ffffff;
}

</style>
