<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-2">
          <h3 class="text-white">User Profile</h3>
        </div>
        <div class="row mt-2">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Clicks" value="150" />
              </div>
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Sign Up" value="17" />
              </div>
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Deposit" value="$1500" />
              </div>
            </div>
            <div class="row mt-xl-4">
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Invested" value="$1500" />
              </div>
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Bonus Holding" value="$500" />
              </div>
              <div class="col-md-4 mb-xl-0 mb-4">
                <default-info-card title="Bonus Paid" value="$100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 mb-8">
        <div class="card">
          <div class="card-header">
            <h5>Top Performance Referral</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Clicks</th>
                  <th>Sign Ups</th>
                  <th>Deposit</th>
                  <th>Invested</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="referral.id" v-for="referral in referrals">
                  <td>{{ referral.id }}</td>
                  <td>{{ referral.name }}</td>
                  <td>{{ referral.clicks }}</td>
                  <td>{{ referral.signUps }}</td>
                  <td>${{ referral.deposit }}</td>
                  <td>${{ referral.invested }}</td>
                  <td class="text-center">
                    <router-link
                      :to="'/admin/affiliate/details'"
                      class="icon icon-shape"
                      ><i class="fas fa-eye"></i
                    ></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";
import DefaultInfoCard from "@/components/Cards/DefaultInfoCard.vue";

// Test Data //
const referrals = reactive([
  {
    id: 12,
    name: "Lorem",
    clicks: 100,
    signUps: 100,
    deposit: 100,
    invested: 100,
  },
  {
    id: 12,
    name: "Lorem",
    clicks: 100,
    signUps: 100,
    deposit: 100,
    invested: 100,
  },
  {
    id: 12,
    name: "Lorem",
    clicks: 100,
    signUps: 100,
    deposit: 100,
    invested: 100,
  },
]);

// Admin List Table //
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const datatable = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
  });
});
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}

.icon {
  background-color: #f8f9fa;
  border-radius: 50%;
}
.icon > i {
  color: #8392ab;
  font-size: 0.875rem;
  line-height: 1.5;
  opacity: 1;
}
.icon:hover > i {
  color: #5e72e4;
}
</style>
