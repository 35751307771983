<template>
  <div class="card">
    <div class="table-responsive p-2">
      <table class="table align-items-center mb-0 text-secondary">
        <thead>
          <tr>
            <th class="col-3 ps-4 py-3">Name</th>
            <th class="col-2">Deposit</th>
            <th class="col-2">Invested</th>
            <th class="col-2">Total Bonus</th>
            <th class="col-2">Bonus Paid</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :key="affiliate.id"
            v-for="affiliate in affiliates"
            class="text-sm"
          >
            <td class="px-4 py-3 font-weight-bold" style="color: #172b4d">
              {{ affiliate.name }}
            </td>
            <td>{{ affiliate.deposit }}</td>
            <td>{{ affiliate.invested }}</td>
            <td>{{ affiliate.totalBonus }}</td>
            <td style="color: #20c997">{{ affiliate.bonusPaid }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

// Test Data
const affiliates = reactive([
  {
    id: 1,
    name: "John Michael",
    deposit: "Buy",
    invested: 1001,
    totalBonus: 1001,
    bonusPaid: 5,
  },
  {
    id: 2,
    name: "Alera Lira",
    deposit: "Buy",
    invested: 1001,
    totalBonus: 1001,
    bonusPaid: 5,
  },
  {
    id: 3,
    name: "Laurent Perrier",
    deposit: "Buy",
    invested: 1001,
    totalBonus: 1001,
    bonusPaid: 5,
  },
  {
    id: 4,
    name: "Michael Levi",
    deposit: "Buy",
    invested: 1001,
    totalBonus: 1001,
    bonusPaid: 5,
  },
  {
    id: 5,
    name: "Richard Gran",
    deposit: "Buy",
    invested: 1001,
    totalBonus: 1001,
    bonusPaid: 5,
  },
]);
</script>

<style scoped>
.table th {
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  opacity: 0.7;
  padding-left: 6px;
}
</style>
