<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-0 text-white">Trader List</h5>
        <!-- Filter Buttons -->
        <div>
          <argon-button type="button" class="border button"
            >Top Traders
          </argon-button>
          <argon-button type="button" class="border button"
            >No. of Copier
          </argon-button>
          <argon-button type="button" class="border button"
            >Risk Level
          </argon-button>
          <argon-button type="button" class="border button"
            >Profit (Result)
          </argon-button>
        </div>
        <!-- Trader Line Charts-->
        <div class="row mt-4">
          <div class="col-12">
            <carousel
              :items-to-show="2"
              mouseDrag="true"
              :breakpoints="carouselSettings.breakpoints"
            >
              <slide v-for="trader in traders" :key="trader.id">
                <CopyTraderCard
                  :trader="{
                    id: trader.id,
                    image: trader.image,
                    name: trader.name,
                    username: trader.username,
                  }"
                  :successFee="{ percentage: trader.successFee }"
                  :profitAllTime="{ percentage: trader.profitAllTime }"
                  :profitOpenPositions="{ percentage: trader.profitOpenPositions }"
                  :people="{ amount: trader.people }"
                  :action="{ route: `/trader/${trader.id}`, label: 'View Trader' }"
                />
              </slide>
              <template #addons></template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import CopyTraderCard from "@/components/Cards/CopyTraderCard.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

// Test Data //
const traders = reactive([
  {
    id: 1,
    image: "team-1.jpg",
    name: "name1",
    username: "username1",
    successFee: 50,
    profitAllTime: 5,
    profitOpenPositions: -5,
    people: 100,
  },
  {
    id: 2,
    image: "team-2.jpg",
    name: "name2",
    username: "username2",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: 5,
    people: 200,
  },
  {
    id: 3,
    image: "team-3.jpg",
    name: "name3",
    username: "username3",
    successFee: 60,
    profitAllTime: 5,
    profitOpenPositions: 5,
    people: 250,
  },
  {
    id: 4,
    image: "team-3.jpg",
    name: "name3",
    username: "username3",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: -5,
    people: 250,
  },
]);

// Carousel Settings //
const carouselSettings = reactive({
  breakpoints: {
    // 0px and up
    0: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // 760px and up
    768: {
      itemsToShow: 2,
      snapAlign: "center",
    },
    // 1500 and up
    1500: {
      itemsToShow: 3,
      snapAlign: "start",
    },
  },
});
</script>

<style scoped>
.button {
  margin: 1rem 1rem 0 0;
  background-color: #596cff;
}
.button:hover {
  color: #596cff;
  background-color: #fbfbfe;
}
</style>
