<template>
  <div class="card col-11 shadow-none">
    <div class="p-4 pb-0 card-header">
      <div class="row">
        <div class="col-8 d-flex">
          <div>
            <img
              :src="require(`@/assets/img/${trader.image}`)"
              class="avatar avatar-sm me-3 mt-2"
              alt="avatar image"
            />
          </div>
          <div class="d-flex flex-column justify-content-center text-start">
            <h6 class="mb-0 text-sm">{{ trader.name }}</h6>
            <p class="text-xs">{{ trader.username }}</p>
          </div>
        </div>
        <div class="col-4 text-end">
          <h5>{{ successFee.percentage }}%</h5>
          <p class="font-weight-bold text-xs">SUCCESS FEE</p>
        </div>
      </div>
    </div>
    <hr class="mx-4 mt-2 mb-0" />
    <div class="card-body pt-3 pb-0">
      <!-- Line Charts -->
      <div class="row">
        <div class="col-lg-12 mb-lg">
          <!-- Profit Chart -->
          <gradient-line-chart-green
            v-if="profitAllTime.percentage > 0"
            :id="trader.id"
            height="200"
            :title="profitAllTime.percentage"
            description="<span class='font-weight-bold'>RETURN (ALL TIME)</span>"
            :secondTitle="profitOpenPositions.percentage"
            secondDescription="<span class='font-weight-bold'>OPEN POSITION</span>"
            :chart="{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              datasets: [
                {
                  label: 'Trader A',
                  data: [80, 90, 80, 80, 60, 70, 50],
                },
              ],
            }"
          />
          <!-- Loss Chart -->
          <gradient-line-chart-red
            v-if="profitAllTime.percentage < 0"
            :id="trader.id"
            height="200"
            :title="profitAllTime.percentage"
            description="<span class='font-weight-bold'>RETURN (ALL TIME)</span>"
            :secondTitle="profitOpenPositions.percentage"
            secondDescription="<span class='font-weight-bold'>OPEN POSITION</span>"
            :chart="{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              datasets: [
                {
                  label: 'Trader A',
                  data: [80, 90, 75, 80, 60, 70, 50],
                },
              ],
            }"
          />
        </div>
      </div>
    </div>
    <div class="p-3 pt-1 card-footer">
      <div class="p-3 d-flex border-radius-lg">
        <p class="my-auto text-start">{{ people.amount }} people copying this trader</p>
        <a
          :href="action.route"
          class="mb-0 ms-auto text-primary font-weight-bolder text-end"
        >
          {{ action.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GradientLineChartGreen from "@/components/Charts/GradientLineChartGreen.vue";
import GradientLineChartRed from "@/components/Charts/GradientLineChartRed.vue";

export default {
  name: "AnnouncementCard",
  components: {
    GradientLineChartGreen,
    GradientLineChartRed,
  },
  props: {
    trader: {
      type: Object,
      default: () => {},
      id: Number,
      image: String,
      name: String,
      username: String,
    },
    successFee: {
      type: Object,
      default: () => {},
      percentage: Number,
    },
    profitAllTime: {
      type: Object,
      default: () => {},
      percentage: Number,
    },
    profitOpenPositions: {
      type: Object,
      default: () => {},
      percentage: Number,
    },
    people: {
      type: Object,
      amount: Number,
      default: () => {},
    },
    action: {
      type: Object,
      route: String,
      label: String,
      default: () => {},
    },
  },
};
</script>
