<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!-- 4 Statistic Cards -->
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Profit" value="$53,000"
              description="<span class='text-sm font-weight-bolder' style='color: #2DCE89'>+55%</span> since yesterday"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Total Assets" value="2,300"
              description="<span class='text-sm font-weight-bolder' style='color: #2DCE89'>+3%</span> since last week"
              :icon="{
                component: 'ni ni-briefcase-24',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Internal Wallet" value="+3,462"
              description="<span class='text-sm font-weight-bolder text-danger'>-2%</span> since last quarter" :icon="{
                component: 'ni ni-credit-card',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="TOTAL INVESTMENT" value="$103,430"
              description="<span class='text-sm font-weight-bolder' style='color: #2DCE89'>+5%</span> than last month"
              :icon="{
                component: 'ni ni-chart-bar-32',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }" />
          </div>
        </div>
        <!-- Performance Line Chart -->
        <div class="row mt-1">
          <div class="col-lg-12 mb-lg">
            <gradient-line-chart id="chart-line" title="Performance"
              description="<span class='font-weight-bold' style='color: #2DCE89'><i class='fa fa-arrow-up'></i> 4% more</span> in 2021"
              :chart="{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                datasets: [
                  {
                    label: 'Trader A',
                    data: [50, 100, 200, 50, 40, 300, 220, 500, 250, 400, 230, 500,],
                  },
                ],
              }" />
          </div>
        </div>
        <!-- Investment Line Charts-->
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="mb-3">Investment</h5>
            <carousel :items-to-show="2" mouseDrag="true" :breakpoints="carouselSettings.breakpoints">
              <slide v-for="investment in investments" :key="investment.id">
                <CopyTraderCard :trader="{
                  id: investment.id,
                  image: investment.image,
                  name: investment.name,
                  username: investment.username,
                }" :successFee="{ percentage: investment.successFee }"
                  :profitAllTime="{ percentage: investment.profitAllTime }"
                  :profitOpenPositions="{ percentage: investment.profitOpenPositions }"
                  :people="{ amount: investment.people }" :action="{ route: 'javascript:;', label: 'View Trader' }" />
              </slide>
              <template #addons></template>
            </carousel>
          </div>
        </div>
        <!-- Following Line Charts-->
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="mb-3">Following</h5>
            <carousel :items-to-show="2" mouseDrag="true" :breakpoints="carouselSettings.breakpoints">
              <slide v-for="following in followings" :key="following.id">
                <CopyTraderCard :trader="{
                  id: following.id,
                  image: following.image,
                  name: following.name,
                  username: following.username,
                }" :successFee="{ percentage: following.successFee }"
                  :profitAllTime="{ percentage: following.profitAllTime }"
                  :profitOpenPositions="{ percentage: following.profitOpenPositions }"
                  :people="{ amount: following.people }" :action="{ route: 'javascript:;', label: 'View Trader' }" />
              </slide>
              <template #addons></template>
            </carousel>
          </div>
        </div>
        <!-- List of Close Trades Table -->
        <div class="row">
          <div class="col-12">
            <ListOfCloseTradesTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import CopyTraderCard from "@/components/Cards/CopyTraderCard.vue";
import ListOfCloseTradesTable from "@/components/Tables/ListOfCloseTradesTable.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

// Test Data //
const investments = reactive([
  {
    id: 1,
    image: "team-1.jpg",
    name: "name1",
    username: "username1",
    successFee: 50,
    profitAllTime: 5,
    profitOpenPositions: -5,
    people: 100,
  },
  {
    id: 2,
    image: "team-2.jpg",
    name: "name2",
    username: "username2",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: 5,
    people: 200,
  },
  {
    id: 3,
    image: "team-3.jpg",
    name: "name3",
    username: "username3",
    successFee: 60,
    profitAllTime: 5,
    profitOpenPositions: 5,
    people: 250,
  },
  {
    id: 4,
    image: "team-3.jpg",
    name: "name3",
    username: "username3",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: -5,
    people: 250,
  },
]);

// Test Data //
const followings = reactive([
  {
    id: 5,
    image: "team-1.jpg",
    name: "name4",
    username: "username4",
    successFee: 50,
    profitAllTime: 5,
    profitOpenPositions: -5,
    people: 100,
  },
  {
    id: 6,
    image: "team-2.jpg",
    name: "name5",
    username: "username5",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: 5,
    people: 200,
  },
  {
    id: 7,
    image: "team-3.jpg",
    name: "name6",
    username: "username6",
    successFee: 60,
    profitAllTime: 5,
    profitOpenPositions: 5,
    people: 250,
  },
  {
    id: 8,
    image: "team-3.jpg",
    name: "name6",
    username: "username6",
    successFee: 60,
    profitAllTime: -5,
    profitOpenPositions: -5,
    people: 250,
  },
]);

// Carousel Settings //
const carouselSettings = reactive({
  breakpoints: {
    // 0px and up
    0: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // 760px and up
    768: {
      itemsToShow: 2,
      snapAlign: "center",
    },
    // 1500 and up
    1500: {
      itemsToShow: 3,
      snapAlign: "start",
    },
  },
});
</script>
