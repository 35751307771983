<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Report A</h5>
            <p class="mb-0 text-sm">
              Reports
            </p>
          </div>
          <DateRange class="px-4" />
          <div class="table-responsive mt-0">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                  <td>{{ transaction.id }}</td>
                  <td>{{ transaction.date }}</td>
                  <td :class="{ green: transaction.type === 'Buy' }">
                    {{ transaction.type }}
                  </td>
                  <td class="font-weight-bold">{{ transaction.status }}</td>
                  <td>{{ transaction.from }}</td>
                  <td>{{ transaction.to }}</td>
                  <td :class="{ green: transaction.percentage > 0 }">
                    {{ transaction.percentage }}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { DataTable } from "simple-datatables";
import DateRange from "@/components/DateRange.vue";

// Test Data
const transactions = reactive([
  {
    id: 121212,
    date: "12/12/2021",
    type: "Buy",
    status: "success",
    from: "1001",
    to: "1001",
    percentage: 5,
  },
  {
    id: 121212,
    date: "12/12/2021",
    type: "Buy",
    status: "success",
    from: "1001",
    to: "1001",
    percentage: 5,
  },
  {
    id: 121212,
    date: "12/12/2021",
    type: "Buy",
    status: "success",
    from: "1001",
    to: "1001",
    percentage: 5,
  },
]);

// Transaction Record Table
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
    perPage: 5,
  });
});
</script>

<style scoped>
table td {
  font-size: 0.875rem;
  line-height: 1.5;
  text-transform: capitalize;
}

.green {
  color: #2dce89;
}
</style>
