<template>
  <div class="container-fluid py-4">
    <div class="card mt-4">
      <div class="card-header">
        <h5>Add Review</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-sm-6">
            <label class="form-label">Name</label>
            <argon-input id="name" type="text" placeholder="eg. Michael" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Rating</label>
            <select
              id="choices-ratings"
              class="form-ratings"
              name="choices-ratings"
              v-model="review.rating"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <!-- Issue: does not update after selection -->
          <div class="col-6 d-flex align-self-end pb-2">
            <span v-for="rating in review.rating" :key="rating">
              <i class="fas fa-star" style="color: #FAB63F;"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <argon-textarea
              id="review"
              class="multisteps-form__textarea mt-0"
              placeholder="Review"
              >Review
            </argon-textarea>
          </div>
        </div>
        <div class="row">
          <div class="button-row d-flex mt-4">
            <argon-button type="button" color="success" class="ms-auto"
              >Submit
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import * as Choices from "choices.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";

// Test Data //
const review = reactive({
  id: 121212,
  name: "Lorem",
  review:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut velit at tortor mattis fringilla ac quis nisi.",
  rating: 5,
});

onMounted(() => {
  // Risk Level //
  if (document.getElementById("choices-ratings")) {
    var rating = document.getElementById("choices-ratings");
    new Choices(rating, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
});
</script>
