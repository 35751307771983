<template>
  <div class="card p-3">
    <div class="row">
      <div class="col-auto">
        <div class="avatar avatar-xl">
          <img
            src="@/assets/img/team-1.jpg"
            alt="profile_image"
            class="shadow-sm border-radius-lg"
          />
        </div>
      </div>
      <div class="col-auto my-auto">
        <div class="h-100">
          <h5 class="mb-1">Sayo Kravits</h5>
          <p class="mb-0 font-weight-bold text-sm">Public Relations</p>
        </div>
      </div>
      <div class="col-4 my-auto ms-auto d-flex justify-content-end">
        <argon-button
          type="button"
          color="success"
          @click="onInvestButtonClicked"
          v-if="props.showInvestButton"
        >
          Invest Now
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import { useRoute, useRouter } from "vue-router";
import { reactive, defineProps } from "vue";

const props = defineProps({
  showInvestButton: {
    type: Boolean,
    required: true,
  },
});

// Routes
const route = useRoute();
const router = useRouter();

const routes = reactive({
  id: route.params.id,
});

function onInvestButtonClicked() {
  router.push(`/trader/${routes.id}/invest`);
}
</script>
