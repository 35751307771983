<template>
  <div class="container-fluid py-7">
    <div class="col-12 col-lg-8 mx-auto mb-4">
      <div class="card p-4 border-radius-xl">
        <h5 class="font-weight-bolder mb-0">Add New Admin</h5>
        <div class="row mt-3">
          <div class="col">
            <label>Email</label>
            <argon-input id="email" type="email" placeholder="Email address" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Password</label>
            <argon-input id="password" type="password" placeholder="Password" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Confirm Password</label>
            <argon-input
              id="confirmpassword"
              type="password"
              placeholder="Confirm Password"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-8">
            <label>Name</label>
            <argon-input id="name" type="text" placeholder="Name" />
          </div>
          <div class="col-12 col-sm-4 mb-sm-0">
            <label>Role</label>
            <select
              id="choices-roles"
              class="form-control"
              name="choices-roles"
            >
              <option value="Role 1">Role 1</option>
              <option value="Role 2">Role 2</option>
              <option value="Role 3">Role 3</option>
            </select>
          </div>
        </div>
        <div class="d-flex mt-4 mt-sm-2 justify-content-end">
          <argon-button type="button" color="success" class="border w-30"
            >Submit
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { onMounted } from "vue";
import Choices from "choices.js";

// Role Dropdown //
onMounted(() => {
  if (document.getElementById("choices-roles")) {
    var role = document.getElementById("choices-roles");
    new Choices(role, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
});
</script>
