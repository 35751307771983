<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse collapse-ref="dashboard" nav-text="Dashboard" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Dashboard' }" mini-icon="L" text="Index" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="admin" nav-text="Admin" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Admin' }" mini-icon="L" text="Admin List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="trader" nav-text="Trader" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Trader' }" mini-icon="L" text="Trader List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="trade" nav-text="Trade" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Trade Inject' }" mini-icon="L" text="Inject Trades" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="review" nav-text="Review" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Review' }" mini-icon="L" text="Review List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="withdrawal" nav-text="Withdrawal" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Withdrawal Verification' }" mini-icon="L" text="Verification" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="user" nav-text="User" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin User' }" mini-icon="L" text="User List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="kyc" nav-text="KYC" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin KYC' }" mini-icon="L" text="KYC List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="affiliate" nav-text="Affiliate" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Affiliate' }" mini-icon="L" text="Dashboard" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="report" nav-text="Report" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Report' }" mini-icon="L" text="Report" />
            </ul>
          </template>
        </sidenav-collapse>
      <!-- Others -->
      </li>
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6">Others</h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse collapse-ref="settings" nav-text="Settings" :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-align-left-2 text-sm text-dark"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Admin Settings' }" mini-icon="L" text="Settings" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    // SidenavCollapseItem
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
