import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/IndexView.vue";
import SignIn from "../views/Authentication/SignInView.vue";
import SignUp from "../views/Authentication/SignUpView.vue";
import ResetPassword from "../views/Authentication/ResetPasswordView.vue";
import Verification from "../views/Authentication/VerificationView.vue";
import Trader from "../views/Trader/ListTraderView.vue";
import TraderView from "../views/Trader/ViewTraderView.vue";
import TraderInvestment from "../views/Trader/InvestmentView.vue"
import TraderTopUpInvestment from "../views/Trader/TopUpInvestmentView.vue"
import TraderStopInvestment from "../views/Trader/StopInvestmentView.vue"
import User from "../views/User/ViewUserView.vue";
import UserEdit from "../views/User/EditUserView.vue";
import UserChangePassword from "../views/User/ChangePasswordView.vue";
import KYCVerification from "../views/KYC/KYCVerificationView.vue";
import SettingsDeleteAccount from "../views/Settings/DeleteAccountView.vue";
import UserAffiliate from "../views/Affiliate/UserAffiliateView.vue"
import Wallet from "../views/Wallet/WalletView.vue";
import DepositWallet from "../views/Wallet/DepositWalletView.vue";
import ClickedDepositWallet from "../views/Wallet/ClickedDepositWalletView.vue";
import RedirectDepositWallet from "../views/Wallet/RedirectDepositWalletView.vue";
import SuccessfulDepositWallet from "../views/Wallet/SuccessfulDepositWalletView.vue";
import TransactionRecordWallet from "../views/Wallet/TransactionRecordWalletView.vue";
import WithdrawalWallet from "../views/Wallet/WithdrawalWalletView.vue";
import AdminDashboard from "../views/Admin/Dashboard/IndexView.vue";
import AdminSignIn from "../views/Admin/Authentication/SignInView.vue";
import AdminAdmin from "../views/Admin/Admin/ListAdminView.vue";
import AdminAdminChangePassword from "../views/Admin/Admin/ChangePasswordView.vue";
import AdminAdminAdd from "../views/Admin/Admin/AddAdminView.vue";
import AdminAdminEdit from "../views/Admin/Admin/EditAdminView.vue";
import AdminTrader from "../views/Admin/Trader/ListTraderView.vue";
import AdminTraderAdd from "../views/Admin/Trader/AddTraderView.vue";
import AdminTraderEdit from "../views/Admin/Trader/EditTraderView.vue";
import AdminTradeInject from "../views/Admin/Trade/InjectView.vue";
import AdminReview from "../views/Admin/Review/ListReviewView.vue";
import AdminReviewEdit from "../views/Admin/Review/EditReviewView.vue";
import AdminReviewAdd from "../views/Admin/Review/AddReviewView.vue";
import AdminWithdrawalVerification from "../views/Admin/Withdrawal/VerificationView.vue";
import AdminUser from "../views/Admin/User/ListUserView.vue";
import AdminUserView from "../views/Admin/User/ViewUserView.vue";
import AdminUserAdd from "../views/Admin/User/AddUserView.vue";
import AdminUserEdit from "../views/Admin/User/EditUserView.vue";
import AdminKYC from "../views/Admin/KYC/ListKYCView.vue";
import AdminKYCView from "../views/Admin/KYC/ViewKYCView.vue";
import AdminAffiliate from "../views/Admin/Affiliate/DashboardView.vue";
import AdminAffiliateDetails from "../views/Admin/Affiliate/DetailsView.vue";
import AdminSettings from "../views/Admin/Settings/SettingsView.vue";
import AdminReport from "../views/Admin/Report/ReportView.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/authentication/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/authentication/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/authentication/resetpassword",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/authentication/verification",
    name: "Verification",
    component: Verification,
  },
  {
    path: "/trader",
    name: "Trader",
    component: Trader,
  },
  {
    path: "/trader/:id",
    name: "Trader View",
    component: TraderView,
  },
  {
    path: "/trader/:id/invest",
    name: "Trader Investment",
    component: TraderInvestment,
  },
  {
    path: "/trader/:id/topup",
    name: "Trader Top Up Investment",
    component: TraderTopUpInvestment ,
  },
  {
    path: "/trader/:id/stop",
    name: "Trader Stop Investment",
    component: TraderStopInvestment ,
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/user/edit",
    name: "User Edit",
    component: UserEdit,
  },
  {
    path: "/user/changepassword",
    name: "User Change Password",
    component: UserChangePassword,
  },
  {
    path: "/kyc/verification",
    name: "KYC Verification",
    component: KYCVerification,
  },
  {
    path: "/settings/deleteaccount",
    name: "Settings Delete Account",
    component: SettingsDeleteAccount,
  },
  {
    path: "/affiliate/user",
    name: "User Affiliate",
    component: UserAffiliate,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
  },
  {
    path: "/wallet/deposit",
    name: "Deposit Wallet",
    component: DepositWallet,
  },
  {
    path: "/wallet/deposit/clicked",
    name: "Clicked Deposit Wallet",
    component: ClickedDepositWallet,
  },
  {
    path: "/wallet/deposit/submitted/redirect",
    name: "Redirect Deposit Wallet",
    component: RedirectDepositWallet,
  },
  {
    path: "/wallet/deposit/submitted/successful",
    name: "Successful Deposit Wallet",
    component: SuccessfulDepositWallet,
  },
  {
    path: "/wallet/transactionrecord",
    name: "Transaction Record Wallet",
    component: TransactionRecordWallet,
  },
  {
    path: "/wallet/withdrawal",
    name: "Withdrawal Wallet",
    component: WithdrawalWallet,
  },
  {
    path: "/admin",
    name: "Admin Dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/authentication/signin",
    name: "Admin SignIn",
    component: AdminSignIn,
  },
  {
    path: "/admin/admin",
    name: "Admin Admin",
    component: AdminAdmin,
  },
  {
    path: "/admin/admin/changepassword",
    name: "Admin Admin Change Password",
    component: AdminAdminChangePassword,
  },
  {
    path: "/admin/admin/add",
    name: "Admin Admin Add",
    component: AdminAdminAdd,
  },
  {
    path: "/admin/admin/edit",
    name: "Admin Admin Edit",
    component: AdminAdminEdit,
  },
  {
    path: "/admin/trader",
    name: "Admin Trader",
    component: AdminTrader,
  },
  {
    path: "/admin/trader/add",
    name: "Admin Trader Add",
    component: AdminTraderAdd,
  },
  {
    path: "/admin/trader/edit",
    name: "Admin Trader Edit",
    component: AdminTraderEdit,
  },
  {
    path: "/admin/trade/inject",
    name: "Admin Trade Inject",
    component: AdminTradeInject,
  },
  {
    path: "/admin/review",
    name: "Admin Review",
    component: AdminReview,
  },
  {
    path: "/admin/review/edit",
    name: "Admin Review Edit",
    component: AdminReviewEdit,
  },
  {
    path: "/admin/review/add",
    name: "Admin Review Add",
    component: AdminReviewAdd,
  },
  {
    path: "/admin/withdrawal/verification",
    name: "Admin Withdrawal Verification",
    component: AdminWithdrawalVerification,
  },
  {
    path: "/admin/user",
    name: "Admin User",
    component: AdminUser,
  },
  {
    path: "/admin/user/view",
    name: "Admin User View",
    component: AdminUserView,
  },
  {
    path: "/admin/user/add",
    name: "Admin User Add",
    component: AdminUserAdd,
  },
  {
    path: "/admin/user/edit",
    name: "Admin User Edit",
    component: AdminUserEdit,
  },
  {
    path: "/admin/kyc",
    name: "Admin KYC",
    component: AdminKYC,
  },
  {
    path: "/admin/kyc/view",
    name: "Admin KYC View",
    component: AdminKYCView,
  },
  {
    path: "/admin/affiliate",
    name: "Admin Affiliate",
    component: AdminAffiliate,
  },
  {
    path: "/admin/affiliate/details",
    name: "Admin Affiliate Details",
    component: AdminAffiliateDetails,
  },
  {
    path: "/admin/settings",
    name: "Admin Settings",
    component: AdminSettings,
  },
  {
    path: "/admin/report",
    name: "Admin Report",
    component: AdminReport,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
