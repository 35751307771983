<template>
  <div class="container-fluid mt-4">
    <!-- Trader Profile -->
    <TraderProfileHeader :showInvestButton="true" />
    <!-- Trader Portfolio -->
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 mb-4">
            <default-doughnut-chart
              title="Portfolio"
              :chart="{
                labels: ['Forex', 'Crypto'],
                datasets: [{ label: 'Portfolio', data: [25, 75] }],
              }"
            />
          </div>
          <!-- Six Info Cards -->
          <div class="col-lg-8">
            <div class="row">
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Min. Deposit" value="150" />
              </div>
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Success Fees" value="30%" />
              </div>
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Risk" value="1" />
              </div>
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Return Rate" value="99%" />
              </div>
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Days Trading" value="500" />
              </div>
              <div class="col-6 col-sm-4 mb-4">
                <default-info-card title="Copier" value="100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Trader Profile and Review -->
    <div class="row">
      <div class="col-lg-6 mb-4">
        <profile-info-card
          title="Profile Information"
          description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }"
          :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: 'fab fa-facebook fa-lg',
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: 'fab fa-twitter fa-lg',
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: 'fab fa-instagram fa-lg',
            },
          ]"
          :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
        />
      </div>
      <div class="col-lg-6 mb-4">
        <div class="card">
          <div class="card-header pb-0 mb-0">
            <h5>Reviews</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable" class="table table-borderless">
              <tbody>
                <tr :key="review.id" v-for="review in reviews">
                  <td class="d-flex flex-column">
                    <div>
                      <h6>{{ review.name }}</h6>
                    </div>
                    <div>
                      <p class="text-sm">{{ review.description }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Navigation Tabs -->
    <nav class="navbar shadow-none">
      <div class="container-fluid justify-content-center">
        <button
          class="btn me-2"
          :class="{
            'btn-white shadow': showMyPerformance,
            'shadow-none': !showMyPerformance,
          }"
          type="button"
          @click="onMyPerformanceButtonClicked"
        >
          My Performance
        </button>
        <button
          class="btn"
          :class="{
            'btn-white shadow': showTradersPerformance,
            'shadow-none': !showTradersPerformance,
          }"
          type="button"
          @click="onTradersPerformanceButtonClicked"
        >
          Trader's Performance
        </button>
      </div>
    </nav>
    <!-- Performance Chart -->
    <div class="row">
      <div class="col-12">
        <bar-chart
          id="1"
          title="Performance (2021)"
          height="200"
          :chart="{
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: {
              label: 'Sales by age',
              data: [15, 20, -12, 60, -20, 15, 20, 15, -40, 5, 30, 55],
            },
          }"
        />
      </div>
    </div>
    <!-- Performance Chart -->
    <div class="row mt-4">
      <div class="col-12">
        <bar-chart
          id="2"
          title="Performance (2021)"
          height="200"
          :chart="{
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: {
              label: 'Sales by age',
              data: [15, 20, -12, 60, -20, 15, 20, 15, -40, 5, 30, 55],
            },
          }"
        />
      </div>
    </div>
    <!-- Performance Table -->
    <PerformanceTable class="mt-4" />
    <!-- Return Chart and Investment Calculator -->
    <div class="row">
      <!-- Return Chart -->
      <div class="col-lg-7 mb-4">
        <gradient-line-chart
          id="chart-line"
          title="Return Chart"
          description="<span class='font-weight-bold' style='color: #2DCE89'><i class='fa fa-arrow-up'></i> 4% more</span> in 2021"
          :chart="{
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: [
              {
                label: 'Trader A',
                data: [50, 100, 200, 50, 40, 300, 220, 500, 250, 400, 230, 500],
              },
            ],
          }"
        />
      </div>
      <!-- Investment Calculator -->
      <div class="col-lg-5 mb-4">
        <div class="card">
          <div class="pb-0 card-header mb-0">
            <h6>Investment Calculator</h6>
          </div>
          <div class="card-body pt-0 pb-2">
            <div class="row">
              <div class="col-12 col-sm-8">
                <label>Deposit</label>
                <argon-input
                  id="deposit"
                  type="number"
                  placeholder="100,000,000"
                />
              </div>
              <div class="col-12 col-sm-4 mb-sm-0">
                <label>Period</label>
                <select
                  id="choices-periods"
                  class="form-control"
                  name="choices-periods"
                >
                  <option value="1">1 year</option>
                  <option value="2">2 year</option>
                  <option value="3">3 year</option>
                </select>
              </div>
            </div>
            <div>
              <label>Forecasted Return</label>
              <table class="table text-center text-sm calculator">
                <tr>
                  <th class="col-3">Jan</th>
                  <th class="col-3">Feb</th>
                  <th class="col-3">Mar</th>
                  <th class="col-3">Apr</th>
                </tr>
                <tr>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                </tr>
                <tr>
                  <th class="col-3">May</th>
                  <th class="col-3">Jun</th>
                  <th class="col-3">Jul</th>
                  <th class="col-3">Aug</th>
                </tr>
                <tr>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                </tr>
                <tr>
                  <th class="col-3">Sep</th>
                  <th class="col-3">Oct</th>
                  <th class="col-3">Nov</th>
                  <th class="col-3">Dec</th>
                </tr>
                <tr>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                  <td>$10</td>
                </tr>
              </table>
              <h6 class="text-end" style="font-weight: 700">= Total: $100</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Trader Statistic -->
    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <div class="pt-4 text-center card-body">
            <h5 class="text-start">Statistic</h5>
            <div class="col-xl-12">
              <div class="row">
                <div class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4">
                  <default-info-card
                    title="Day"
                    value="5%"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Week"
                    value="5%"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Month"
                    value="30%"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Quarter"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Year"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="All Time"
                    value="150"
                    class="shadow-none"
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4">
                  <default-info-card
                    title="Max Drawdown"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Total Trading Days"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Avg. Daily Wins"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Profitable Days"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Avg. Daily Lost"
                    value="150"
                    class="shadow-none"
                  />
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-xxl-2 mb-xl-0 mb-4 border-start"
                >
                  <default-info-card
                    title="Unprofitable Days"
                    value="150"
                    class="shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer pt-0 pb-1">
            <div class="col-xl-12">
              <div class="row border-top">
                <default-info-card
                  title="Most Traded Symbol"
                  value="EURUSD | AUDCAD | USDJPY | USDT | BTC | ETH "
                  class="shadow-none rounded-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Trader Close Trades  -->
    <div class="row">
      <div class="col-12 mb-8">
        <ListOfCloseTradesTable />
      </div>
    </div>
  </div>
</template>

<script setup>
import DefaultInfoCard from "@/components/Cards/DefaultInfoCard.vue";
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import DefaultDoughnutChart from "@/components/Charts/DefaultDoughnutChart.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ListOfCloseTradesTable from "@/components/Tables/ListOfCloseTradesTable.vue";
import ProfileInfoCard from "@/components/Cards/ProfileInfoCard.vue";
import { reactive, onMounted, ref } from "vue";
import { DataTable } from "simple-datatables";
import Choices from "choices.js";
import PerformanceTable from "@/components/Tables/PerformanceTable.vue";
import TraderProfileHeader from "@/components/Cards/TraderProfileHeader.vue";

// Navbar Buttons //
const showMyPerformance = ref(true);
const showTradersPerformance = ref(false);

const onMyPerformanceButtonClicked = () => {
  showMyPerformance.value = true;
  showTradersPerformance.value = false;
};

const onTradersPerformanceButtonClicked = () => {
  showTradersPerformance.value = true;
  showMyPerformance.value = false;
};

// Test Data
const reviews = reactive([
  {
    id: 1,
    name: "Michael Lewis",
    description:
      "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!",
  },
  {
    id: 2,
    name: "Michael Lewis",
    description:
      "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!",
  },
  {
    id: 3,
    name: "Michael Lewis",
    description:
      "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!",
  },
]);

// Reviews Table
onMounted(() => {
  // eslint-disable-next-line no-unused-vars
  const dataTable = new DataTable("#datatable", {
    searchable: false,
    fixedHeight: true,
    perPage: 2,
    perPageSelect: [2, 5],
  });
});

// Calculator Period Dropdown //
onMounted(() => {
  if (document.getElementById("choices-periods")) {
    var period = document.getElementById("choices-periods");
    new Choices(period, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
});
</script>

<style scoped>
.sub-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #172b4d;
}

.calculator th {
  line-height: 0.8rem;
}

.calculator td {
  font-size: 0.875rem;
  line-height: 1.2;
  background-color: #f0f0ff;
  text-transform: capitalize;
}
</style>
