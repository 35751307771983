<template>
  <div class="card p-4 border-radius-xl bg-white">
    <h5 class="font-weight-bolder mb-0">Deposit</h5>
    <div class="table-responsive p-2">
      <table class="table align-items-center mb-0">
        <thead>
          <tr class="text-center">
            <th class="col-1 text-start p-2"></th>
            <th class="col-5 text-start p-2">Payment Method</th>
            <th class="col-3">Processing Time</th>
            <th class="col-1">Fees</th>
            <th class="col-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :key="paymentMethod.id"
            v-for="paymentMethod in paymentMethods"
            v-show="paymentMethod.canDeposit"
            class="text-sm text-center font-weight-bold"
            style="color: #172b4d"
          >
            <td class="text-start">{{ paymentMethod.id }}</td>
            <td class="text-start">
              <img
                :src="require(`@/assets/img/logos/${paymentMethod.imgFile}`)"
                :alt="`${paymentMethod.paymentMethod}`"
                width="24"
                class="me-3"
              />
              {{ paymentMethod.paymentMethod }}
            </td>
            <td>{{ paymentMethod.processingTime }}</td>
            <td>{{ paymentMethod.fees }}%</td>
            <td>
              <argon-button
                type="button"
                color="success"
                class="border w-100 font-weight-normal"
                @click="onDepositButtonClicked"
                >DEPOSIT
              </argon-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="my-3 text-danger text-xs">
    *The following payment methods are not supported in your country.
  </div>
  <div class="card p-4 border-radius-xl bg-white">
    <div class="table-responsive p-2">
      <table class="table align-items-center mb-0">
        <!-- Collapsed header just to set table spacing -->
        <thead style="visibility: collapse">
          <tr class="text-center">
            <th class="col-1 text-start p-2"></th>
            <th class="col-5 text-start p-2">Payment Method</th>
            <th class="col-3">Processing Time</th>
            <th class="col-1">Fees</th>
            <th class="col-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :key="paymentMethod.id"
            v-for="paymentMethod in paymentMethods"
            v-show="!paymentMethod.canDeposit"
            class="text-sm text-center font-weight-bold"
            style="color: #c4c4c4"
          >
            <td class="text-start">{{ paymentMethod.id }}</td>
            <td class="text-start">
              <img
                :src="require(`@/assets/img/logos/${paymentMethod.imgFile}`)"
                :alt="`${paymentMethod.paymentMethod}`"
                width="24"
                class="me-3"
              />
              {{ paymentMethod.paymentMethod }}
            </td>
            <td>{{ paymentMethod.processingTime }}</td>
            <td>{{ paymentMethod.fees }}%</td>
            <td>
              <argon-button
                type="button"
                class="border w-100 font-weight-normal text-white"
                style="background-color: #c4c4c4"
                disabled
                >DEPOSIT
              </argon-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/ArgonButton.vue";

// Test Data
const paymentMethods = reactive([
  {
    id: 1,
    imgFile: "crytocurrency.png",
    paymentMethod: "Crytocurrency",
    processingTime: "Instant",
    fees: 0,
    canDeposit: true,
  },
  {
    id: 2,
    imgFile: "paypal.png",
    paymentMethod: "Paypal",
    processingTime: "24 Hours",
    fees: 0,
    canDeposit: false,
  },
  {
    id: 3,
    imgFile: "bankTransfer.png",
    paymentMethod: "Bank Transfer",
    processingTime: "Instant",
    fees: 0,
    canDeposit: false,
  },
  {
    id: 4,
    imgFile: "onlineBanking.png",
    paymentMethod: "Online Banking",
    processingTime: "Instant",
    fees: 0,
    canDeposit: false,
  },
  {
    id: 5,
    imgFile: "creditCard.png",
    paymentMethod: "Credit Card",
    processingTime: "Instant",
    fees: "2-5",
    canDeposit: false,
  },
]);

// Routes
const router = useRouter();

function onDepositButtonClicked() {
  router.push("deposit/clicked");
}
</script>

<style scoped>
.table th {
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
}
</style>
