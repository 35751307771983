<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse :collapse="false" collapse-ref="/" nav-text="Dashboard"
          :class="getRoute() === '' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-atom text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="trader" nav-text="Trader" :class="getRoute() === 'trader' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Trader' }" mini-icon="L" text="Trader List" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="user" nav-text="User" :class="getRoute() === 'user' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-circle-08 text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'User' }" mini-icon="L" text="View Profile" />
              <sidenav-item :to="{ name: 'User Edit' }" mini-icon="L" text="Edit Profile" />
              <sidenav-item :to="{ name: 'User Change Password' }" mini-icon="L" text="Change Password" />
              <sidenav-item :to="{ name: 'KYC Verification' }" mini-icon="L" text="Verification (KYC)" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="affiliate" nav-text="Affiliate"
          :class="getRoute() === 'affiliate' ? 'active' : ''">
          <template #icon>
            <i class="fa fa-users text-sm opacity-10" style="color: #2dce89; margin-top: -5px;"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'User Affiliate' }" mini-icon="L" text="User Affiliate" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="wallet" nav-text="Wallet" :class="getRoute() === 'wallet' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-money-coins  text-sm opacity-10" style="color: #6f42c1;"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Wallet' }" mini-icon="L" text="View Wallet" />
              <sidenav-item :to="{ name: 'Deposit Wallet' }" mini-icon="L" text="Deposit" />
              <sidenav-item :to="{ name: 'Withdrawal Wallet' }" mini-icon="L" text="Request Withdrawal" />
              <sidenav-item :to="{ name: 'Transaction Record Wallet' }" mini-icon="L" text="Transaction Record" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6">Others</h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse collapse-ref="settings" nav-text="Settings"
          :class="getRoute() === 'settings' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-align-left-2 text-sm text-dark"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Settings Delete Account' }" mini-icon="L" text="Delete Account" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    // SidenavCollapseItem
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
