<template>
  <Sidenav v-if="isShowNavbar" />
  <AdminSidenav v-if="isShowAdminNavbar" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <Navbar v-if="isShowNavbar" />
    <AdminNavbar v-if="isShowAdminNavbar" />
    <router-view />
    <!-- <Footer /> -->
  </main>
</template>

<style>

</style>

<script setup>
// import Footer from "@/components/PageLayout/Footer.vue"
import Navbar from "@/components/Navbars/Navbar.vue";
import AdminNavbar from "@/components/AdminNavbars/Navbar.vue";
import Sidenav from "@/components/Sidenav";
import AdminSidenav from "@/components/AdminSidenav";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const isShowNavbar = computed(() => {
  if (
    route.matched.some((route) => route.path.includes("/authentication")) ||
    route.matched.some((route) => route.path.includes("/depositwallet/submitted")) ||
    route.matched.some((route) => route.path.includes("/admin"))
  ) {
    return false;
  } else {
    return true;
  }
});

const isShowAdminNavbar = computed(() => {
  if (route.matched.some((route) => route.path.includes("/authentication"))) {
    return false;
  } else if (route.matched.some((route) => route.path.includes("/admin"))) {
    return true;
  } else {
    return false;
  }
});
</script>

<style>
div.swal2-container {
  z-index: 10000;
}

body .font-weight-bold {
  font-weight: 700 !important;
}

body .font-weight-bolder {
  font-weight: 800 !important;
}
</style>
